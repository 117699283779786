import styled, { css } from 'styled-components';

import { Container as ContainerBase, theme } from '@humanoids/ui';

import SimpleCheckIcon from '../../images/simple-check-icon.svg';

export const Background = styled.div(
  ({ theme }) => css`
    background: linear-gradient(
      180deg,
      ${theme.backgroundColor.primary} 0%,
      ${theme.backgroundColor.primary} 15%,
      ${theme.backgroundColor.quaternary} 15%,
      ${theme.backgroundColor.quaternary} 85%,
      ${theme.backgroundColor.primary} 85%,
      ${theme.backgroundColor.primary} 100%
    );
    padding: 0 26px;
  `
);

export const Container = styled(ContainerBase)(
  ({ theme }) => css`
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 24px 0px hsla(0, 0%, 0%, 0.1);
    max-width: 860px;
    padding: 16px 24px;

    @media (min-width: ${theme.breakpoints.medium}) {
      padding: 24px;
    }
  `
);

export const Header = styled.div(
  ({ theme }) => css`
    border-bottom: 1px solid ${theme.textColor.secondary};
    display: grid;
    gap: 40px;
    grid-template-columns: min-content 1fr;
    padding-bottom: 16px;
  `
);

export const Icon = styled.div`
  align-self: center;
  display: block;
  height: 100%;
  position: relative;
  width: 46px;
`;

export const imageMediaCondition = `
  (max-width: ${theme.breakpoints.medium}) 100vw,
  46px`;

export const HeaderWrapper = styled.div`
  display: grid;
`;

export const StartDates = styled.div(
  ({ theme }) => css`
    border-bottom: 1px solid ${theme.textColor.secondary};
    display: grid;
    gap: 16px;
    padding: 16px 0;

    time {
      font-weight: 700;

      &:nth-child(1),
      &:nth-child(2) {
        color: ${theme.brandColors.primary.dark};
      }

      &:nth-child(3),
      &:last-child {
        color: ${theme.brandColors.secondary.dark};
      }
    }
  `
);

export const Requirements = styled.div(
  ({ theme }) => css`
    border-bottom: 1px solid ${theme.textColor.secondary};
    display: grid;
    gap: 16px;
    padding: 16px 0;
  `
);

export const List = styled.ul`
  list-style-image: url('${SimpleCheckIcon}');
  padding-left: 25px;

  li {
    padding: 0 0 16px 16px;
  }
`;

export const ButtonContainer = styled.div(
  ({ theme }) => css`
    padding: 16px 0 0;

    a,
    button {
      color: ${theme.textColor.primary};
      justify-content: center;
      padding-right: 18px;
      text-decoration: none;
      width: calc(100% - 32px);

      svg {
        display: none;
      }
    }

    p {
      margin: 0 auto;
    }

    @media (min-width: ${theme.breakpoints.medium}) {
      padding: 24px 0 0;

      a,
      button {
        margin: 0 auto;
        width: 60%;
      }
    }
  `
);

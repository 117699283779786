import styled, { css } from 'styled-components';

import { Block as BlockBase } from '../block';
import { Container as ContainerBase } from '@humanoids/ui';

export const Background = styled.div(
  ({ theme }) => css`
    background-color: ${theme.backgroundColor.secondary};
    margin-bottom: -64px;

    @media (min-width: ${theme.breakpoints.medium}) {
      margin-bottom: -128px;
    }
  `
);

export const Container = styled(ContainerBase)`
  display: grid;
  gap: var(--spacing);
  padding: var(--spacing) 0;
`;

export const Block = styled(BlockBase)(
  ({ theme }) => css`
    width: 100%;

    //TODO: remove when :has() selector is implemented in Firefox, this styling is also in the involved components
    & > h1,
    h2,
    h3,
    h4,
    h5,
    p {
      :only-child {
        color: ${theme.textColor.contrast};
      }
    }

    &:not(:first-child) {
      margin-top: 34px;
    }

    @media (min-width: ${theme.breakpoints.medium}) {
      &:not(:first-child) {
        margin-top: 64px;
      }
    }
  `
);

import styled, { css } from 'styled-components';
import LinkBase from 'next/link';

import {
  Author as AuthorBase,
  Card as CardBase,
  Container as ContainerBase,
  Heading as HeadingBase,
  Text as TextBase,
  theme,
} from '@humanoids/ui';

export const Container = styled(ContainerBase)(
  ({ theme }) => css`
    display: grid;
    gap: var(--spacing);
    grid-template-columns: 1fr;
    width: 100%;

    @media (min-width: ${theme.breakpoints.medium}) {
      grid-template-columns: repeat(12, 1fr);
    }
  `
);

export const Link = styled(LinkBase)(
  ({ theme }) => css`
    color: ${theme.textColor.primary};
    text-decoration: none;
    position: relative;

    &:has(${Text}):hover {
      p {
        text-decoration: underline;
      }
    }

    @media (min-width: ${theme.breakpoints.medium}) {
      &:nth-child(3n + 1) {
        grid-column: 1 / span 4;
      }

      &:nth-child(3n + 2) {
        grid-column: 5 / span 4;
      }

      &:nth-child(3n + 3) {
        grid-column: 9 / span 4;
      }

      &:first-child {
        display: grid;
        grid-template-columns: 65% 1fr;
        grid-column: 1 / span 12;
        height: 400px;

        ${Image} {
          height: inherit;
          width: auto;
        }

        ${Overlay} {
          height: 400px;
        }

        ${Card} {
          height: inherit;

          p {
            -webkit-line-clamp: 7;
          }
        }
      }

      &:nth-child(2) {
        grid-column: 1 / span 6;
      }

      &:nth-child(3) {
        grid-column: 7 / span 6;
      }
    }
  `
);

export const Heading = styled(HeadingBase)`
  color: ${theme.textColor.contrast};
  left: 16px;
  position: absolute;
  top: 16px;

  @media (min-width: ${theme.breakpoints.medium}) {
    left: 24px;
    top: 24px;
  }
`;

export const Image = styled.div(
  ({ theme }) => css`
    height: 240px;
    position: relative;
    width: auto;

    @media (min-width: ${theme.breakpoints.medium}) {
      height: 280px;
    }

    img {
      object-fit: cover;
    }
  `
);

export const imageMediaCondition = `
  (max-width: ${theme.breakpoints.medium}) 100vw,
  718px
`;

export const Overlay = styled.div(
  ({ theme }) => css`
    background: linear-gradient(
      133.96deg,
      rgba(0, 0, 0, 0.54) 0%,
      rgba(0, 0, 0, 0.54) 24.75%,
      rgba(0, 0, 0, 0) 100%
    );
    height: 240px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    @media (min-width: ${theme.breakpoints.medium}) {
      height: 280px;
    }
  `
);

export const Card = styled(CardBase)(
  ({ theme }) => css`
    p {
      -webkit-line-clamp: 3;
    }

    @media (min-width: ${theme.breakpoints.medium}) {
      grid-template-rows: min-content 1fr;
      height: calc(100% - 280px);
    }
  `
);

export const Text = styled(TextBase)``;

export const Author = styled(AuthorBase)`
  align-self: end;
`;

import React, { useContext } from 'react';
import Head from 'next/head';

import { LocationType, PageType } from '@/services';
import { StoreContext } from '../store-provider';
import { useGeneratePath } from '@/hooks/use-generate-path';

interface WebPageProps {
  locations: LocationType[];
  page: PageType;
}

interface BlogPostProps {
  page: PageType;
}

export const WebPage = ({ locations, page }: WebPageProps) => {
  const generatePath = useGeneratePath();

  const [{ configuration }] = useContext(StoreContext);
  const socials = configuration.at(0).socialPlatform;

  return (
    <Head>
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@graph': [
            {
              '@type': 'WebPage',
              name: page.title,
              description: page.description ?? '',
              url: `${process.env.NEXT_PUBLIC_HOSTNAME}${generatePath(page)}`,
            },
            {
              '@type': 'WebSite',
              name: configuration.at(0).name,
              url: process.env.NEXT_PUBLIC_HOSTNAME,
              headline: page.title,
              mainEntity: {
                '@type': 'LocalBusiness',
                name: configuration.at(0).name,
                email: locations.map((location) => location.emailAddress),
                telephone: locations.map((location) => location.phoneNumber),
                address: locations.map((location) => ({
                  '@type': 'PostalAddress',
                  addressCountry: location.country,
                  addressLocality: location.city,
                  postalCode: location.postalCode,
                  streetAddress: location.streetAddress,
                })),
              },
            },
            {
              '@type': 'Organization',
              address: locations.map((location) => ({
                '@type': 'PostalAddress',
                addressCountry: location.country,
                addressLocality: location.city,
                postalCode: location.postalCode,
                streetAddress: location.streetAddress,
              })),
              contactPoint: locations.map((location) => ({
                '@type': 'ContactPoint',
                email: location.emailAddress,
                image: location.photo.asset.url,
                telephone: location.phoneNumber,
              })),
              logo: {
                '@type': 'ImageObject',
                url: `${process.env.NEXT_PUBLIC_HOSTNAME}/images/humanoids-logo.svg`,
              },
              legalName: configuration.at(0).name,
              sameAs: socials.map((social) => social.url),
              url: `${process.env.NEXT_PUBLIC_HOSTNAME}`,
            },
          ],
        })}
      </script>
    </Head>
  );
};

export const BlogPost = ({ page }: BlogPostProps) => {
  const generatePath = useGeneratePath();
  const [{ configuration }] = useContext(StoreContext);

  return (
    <Head>
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org/',
          '@type': 'BlogPosting',
          articleBody: page.blocks
            .map((block) =>
              block.__typename === 'ArticleBlock' ||
              block.__typename === 'CaseBlock'
                ? block.contentRaw?.map(
                    (content) => content.children?.map((child) => child.text)
                  )
                : ''
            )
            .join(' '),
          // TODO: make it possible to have multiple authors
          author: {
            '@type': 'Person',
            name: page.author?.name,
          },
          creator: {
            '@type': 'Person',
            name: page.author?.name,
          },
          datePublished: page.publicationDateTime,
          dateModified: page.latestUpdateDateTime || page.publicationDateTime,
          description: page.description,
          headline: `${page.title}`,
          image: page.image?.asset.url,
          keywords: page.blocks.flatMap((block) =>
            block.__typename === 'ArticleBlock' ? block.tags : ''
          ),
          mainEntityOfPage: {
            '@type': 'WebPage',
            '@id': process.env.NEXT_PUBLIC_HOSTNAME,
          },
          publisher: configuration.at(0).name,
          url: `${process.env.NEXT_PUBLIC_HOSTNAME}${generatePath(page)}`,
        })}
      </script>
      <meta name="author" content={page.author?.name} />
    </Head>
  );
};

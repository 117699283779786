import { gql } from 'graphql-request';

import { ArticlesLatestBlock } from '../articles-latest-block';
import { CardsBlock } from '../cards-block';
import { CaseSelectionBlock } from '../case-selection-block';
import { ColumnsBlock } from '../columns-block';
import { HeadingBlock } from '../heading-block';
import { ImagesBlock } from '../images-block';
import { TeamBlock } from '../team-block';
import { TextBlock } from '../text-block';

import { AlternateBlockType } from '@/services';
import * as Styled from './AlternateBlock.styled';

export const alternateBlockFragment = gql`
  fragment alternateBlock on AlternateBlock {
    __typename
    _key
    blocks {
      ...articlesLatestBlock
      ...cardsBlock
      ...caseSelectionBlock
      ...columnsBlock
      ...headingBlock
      ...imagesBlock
      ...teamBlock
      ...textBlock
    }
  }
`;

export const AlternateBlock = ({ blocks }: AlternateBlockType) => (
  <Styled.Background>
    <Styled.Container>
      {blocks?.map((block) => (
        <Styled.Block key={block._key}>
          {block.__typename === 'ArticlesLatestBlock' && (
            <ArticlesLatestBlock {...block} />
          )}
          {block.__typename === 'CardsBlock' && <CardsBlock {...block} />}
          {block.__typename === 'CaseSelectionBlock' && (
            <CaseSelectionBlock {...block} />
          )}
          {block.__typename === 'ColumnsBlock' && <ColumnsBlock {...block} />}
          {block.__typename === 'HeadingBlock' && <HeadingBlock {...block} />}
          {block.__typename === 'ImagesBlock' && <ImagesBlock {...block} />}
          {block.__typename === 'TeamBlock' && <TeamBlock {...block} />}
          {block.__typename === 'TextBlock' && <TextBlock {...block} />}
        </Styled.Block>
      ))}
    </Styled.Container>
  </Styled.Background>
);

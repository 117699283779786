import styled, { css } from 'styled-components';

import {
  Container as ContainerBase,
  Text as TextBase,
  theme,
} from '@humanoids/ui';

import { ReactComponent as QuotationMarkIconSvg } from '@/images/quotation-mark-icon.svg';

export const Container = styled(ContainerBase)(
  ({ theme }) => css`
    position: relative;

    &:not(:has(${Image})):not(:has(${Video})) {
      display: block;

      p {
        text-align: center;
      }

      ${Person} {
        align-items: center;
        flex-direction: column;
        margin-bottom: 0;
        position: relative;
      }

      ${Avatar} {
        height: 64px;
        width: 64px;
      }

      ${QuotationMarkIcon} {
        position: absolute;
        right: 5px;
      }

      @media (min-width: ${theme.breakpoints.medium}) {
        padding-left: calc(var(--spacing) * 2);
        padding-right: calc(var(--spacing) * 2);
      }

      @media (min-width: ${theme.breakpoints.large}) {
        padding-left: calc(var(--spacing) * 4);
        padding-right: calc(var(--spacing) * 4);
      }
    }

    @media (min-width: ${theme.breakpoints.medium}) {
      column-gap: 68px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr auto;
    }
  `
);

export const Person = styled.div(
  ({ theme }) => css`
    align-items: center;
    display: flex;
    gap: 16px;
    margin-bottom: 28px;

    p {
      display: grid;
    }

    @media (min-width: ${theme.breakpoints.medium}) {
      grid-row: 2 / 2;
      margin-bottom: 0;
      position: relative;
    }
  `
);

export const avatarMediaCondition = `
   (max-width: ${theme.breakpoints.medium}) 100vw,
  50vw
`;

export const Avatar = styled.div`
  min-height: 40px;
  position: relative;
  min-width: 40px;

  img {
    border-radius: 50%;
  }
`;

export const Text = styled(TextBase)`
  font-style: italic;
  margin-bottom: var(--spacing);
`;

export const QuotationMarkIcon = styled(QuotationMarkIconSvg)(
  ({ theme }) => css`
    margin-left: auto;

    @media (min-width: ${theme.breakpoints.medium}) {
      left: 280px;
      position: absolute;
      top: -30px;
    }
  `
);

export const Image = styled.div(
  ({ theme }) => css`
    height: 216px;
    position: relative;

    img {
      object-fit: cover;
    }

    @media (min-width: ${theme.breakpoints.medium}) {
      grid-column: 2 / span 2;
      grid-row-end: span 2;
      height: 100%;
    }
  `
);

export const Video = styled.div(
  ({ theme }) => css`
    video {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }

    @media (min-width: ${theme.breakpoints.medium}) {
      grid-column: 2 / span 2;
      grid-row-end: span 2;
      margin-top: 0;
    }
  `
);

import { GetStaticPaths, GetStaticProps } from 'next';

import { Blocks } from '../components/blocks';
import * as JsonLd from '../components/json-ld';
import { OpenGraph } from '../components/open-graph';
import { Seo } from '../components/seo';
import { generatePath } from '../services/generate-path';
import { graphQlRequestSdk } from '../services/graphql';

export const getStaticPaths: GetStaticPaths = async () => {
  const { configuration, pages } = await graphQlRequestSdk.Paths();

  return {
    paths: pages.map((page) => ({
      params: { path: generatePath(page, configuration) },
      locale: page.i18n_lang,
    })),
    fallback: 'blocking',
  };
};

export const getStaticProps: GetStaticProps = async ({
  defaultLocale,
  locale,
  params: { path },
}) => {
  const [{ configuration }, { redirects }] = await Promise.all([
    graphQlRequestSdk.Configuration(),
    graphQlRequestSdk.Redirects(),
  ]);

  const redirect =
    path &&
    redirects.find(
      (redirect) =>
        redirect.path === `/${(path as []).join('/')}` &&
        redirect.page.i18n_lang === locale
    );

  if (redirect) {
    return {
      redirect: {
        destination: `/${
          locale !== defaultLocale ? `${locale}/` : ''
        }${generatePath(redirect.page, configuration).join('/')}`,
        permanent: redirect.permanent,
      },
    };
  }

  const { homepage } = configuration.find(
    ({ i18n_lang }) => i18n_lang === locale
  );

  const parentSlug = path?.length > 1 ? (path as []).at(-2) : null;
  const slug = !path ? homepage.slug.current : (path as []).at(-1);

  const data = await graphQlRequestSdk.Page({ locale, slug });
  const { locations } = await graphQlRequestSdk.Locations();

  const page = !parentSlug
    ? data.pages.find(
        ({ parentPage, slug: { current } }) => !parentPage && slug === current
      )
    : data.pages.find(
        ({ parentPage }) => parentPage?.slug.current === parentSlug
      );

  if (!page) {
    return {
      notFound: true,
      revalidate: 60,
    };
  }

  return {
    props: {
      locations: locations,
      page,
    },
    revalidate: 10,
  };
};

export const Page = ({ locations, page }) => {
  if (!page) {
    return null;
  }

  return (
    <>
      <Seo page={page} />
      <OpenGraph page={page} />
      {page.type === 'article' || page.type === 'case' ? (
        <JsonLd.BlogPost page={page} />
      ) : (
        <JsonLd.WebPage locations={locations} page={page} />
      )}
      <Blocks page={page} />
    </>
  );
};

export default Page;

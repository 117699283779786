import styled, { css } from 'styled-components';

import CheckIconSvg from '@/images/check-icon.svg';

export const List = styled.ul<{ listStyleType: string }>`
  ${({ listStyleType }) =>
    listStyleType === 'check' &&
    css`
      li {
        background: url('${CheckIconSvg}') no-repeat left 8px;
        padding: 3px 0 0 30px;
        list-style: none;
      }
    `}

  ${({ listStyleType }) =>
    listStyleType === 'disc' &&
    css`
      list-style-position: inside;
      list-style-type: disc;
    `}
`;

import styled, { css } from 'styled-components';

import { ReactComponent as ChevronDownSvg } from '@/images/chevron-down.svg';

import { Container as ContainerBase } from '@humanoids/ui';

export const Container = styled(ContainerBase)`
  display: grid;
  gap: 16px;
`;

interface StyledChevronProps {
  direction: 'down' | 'up';
}

export const Chevron = styled(ChevronDownSvg)<StyledChevronProps>(
  ({ direction, theme }) => css`
    align-self: center;
    fill: ${theme.textColor.primary};
    grid-row: 1 / span 2;
    height: 14px;
    transform: rotate(${direction === 'down' ? 0 : 180}deg);
    transition: transform 0.3s ease-in-out;
    width: 14px;
  `
);

export const AccordionItem = styled.div(
  ({ theme }) => css`
    background-color: ${theme.backgroundColor.contrast};
    border: 1px solid ${theme.textColor.secondary};
    border-radius: 8px;
    cursor: pointer;
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1fr min-content;
    grid-template-rows: repeat(3, min-content);
    padding: 16px 24px;
  `
);

export const Title = styled.h5(
  ({ theme }) => css`
    color: ${theme.textColor.primary};
    font-size: 18px;
    grid-row: 2;
    line-height: 28px;

    @media (min-width: ${theme.breakpoints.medium}) {
      font-size: 20px;
      line-height: 32px;
    }
  `
);

export const Subtitle = styled.h6(
  ({ theme }) => css`
    color: ${theme.textColor.primary};
    font-size: 16px;
    font-weight: 400;
    grid-row: 1;
    line-height: 24px;
  `
);

export const Line = styled.hr(
  ({ theme }) => css`
    background-color: ${theme.textColor.secondary};
    border: none;
    height: 1px;
    margin-top: 16px;
  `
);

interface StyledContentProps {
  _key: string;
  contentRefs: { [_key: string]: HTMLDivElement };
  visible: boolean;
}

export const Content = styled.div<StyledContentProps>`
  display: grid;
  gap: 16px;
  grid-column: 1 / span 2;
  max-height: ${({ contentRefs, _key, visible }) =>
    visible ? contentRefs[_key].scrollHeight : 0}px;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
`;

import { useRouter } from 'next/compat/router';
import Image from 'next/image';
import Link from 'next/link';
import { useIntl } from 'react-intl';
import { gql } from 'graphql-request';

import { Button, Heading, Text, parseMarkdown } from '@humanoids/ui';

import { TraineeshipApplicationBlockType } from '../../services/graphql';

import * as Styled from './TraineeshipApplicationBlock.styled';

export const traineeshiApplicationBlockFragment = gql`
  fragment traineeshipApplicationBlock on TraineeshipApplicationBlock {
    __typename
    _key
    cta
    disabled
    nextRegistrationDateClosed
    nextRegistrationDateOpen
    nextStartDate
    icon {
      alt
      ...asset
    }
    requirements
    secondRegistrationDateClosed
    secondRegistrationDateOpen
    secondStartDate
    subtitle
    title
    url
  }
`;

interface DateFormattedProps {
  date: string;
  format: Intl.DateTimeFormatOptions;
  locale: string;
}

const DateFormatted = ({ date, format, locale }: DateFormattedProps) => (
  <time dateTime={date}>{new Date(date).toLocaleString(locale, format)}</time>
);

export const TraineeshipApplicationBlock = ({
  cta,
  disabled,
  nextRegistrationDateClosed,
  nextRegistrationDateOpen,
  nextStartDate,
  icon,
  requirements,
  secondRegistrationDateClosed,
  secondRegistrationDateOpen,
  secondStartDate,
  subtitle,
  title,
  url,
}: TraineeshipApplicationBlockType) => {
  const intl = useIntl();
  const router = useRouter();

  const monthFormat: Intl.DateTimeFormatOptions = { month: 'long' };

  const dayMonthFormat: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'long',
  };

  const renderDate = (date: string, format: Intl.DateTimeFormatOptions) => (
    <DateFormatted date={date} format={format} locale={router.locale} />
  );

  return (
    <Styled.Background>
      <Styled.Container>
        <Styled.Header>
          <Styled.Icon>
            <Image
              alt={icon.alt}
              fill
              sizes={Styled.imageMediaCondition}
              src={icon.asset.url}
            />
          </Styled.Icon>
          <Styled.HeaderWrapper>
            <Heading tertiary>{title}</Heading>
            <Text
              dangerouslySetInnerHTML={{
                __html: parseMarkdown(subtitle),
              }}
            />
          </Styled.HeaderWrapper>
        </Styled.Header>
        <Styled.StartDates>
          <Heading quaternary>Start</Heading>
          <Text>
            {intl.formatMessage(
              { id: 'traineeshipApplicationDates' },
              {
                nextRegistrationDateOpen: renderDate(
                  nextRegistrationDateOpen,
                  dayMonthFormat
                ),
                nextRegistrationDateClosed: renderDate(
                  nextRegistrationDateClosed,
                  dayMonthFormat
                ),
                secondRegistrationDateOpen: renderDate(
                  secondRegistrationDateOpen,
                  dayMonthFormat
                ),
                secondRegistrationDateClosed: renderDate(
                  secondRegistrationDateClosed,
                  dayMonthFormat
                ),
              }
            )}
          </Text>
          <Text>
            {intl.formatMessage(
              {
                id: 'traineeshipStartDates',
              },
              {
                nextStartDate: renderDate(nextStartDate, monthFormat),

                secondStartDate: renderDate(secondStartDate, monthFormat),
              }
            )}
          </Text>
        </Styled.StartDates>
        <Styled.Requirements>
          <Heading quaternary>
            {intl.formatMessage({
              id: 'requirements',
            })}
          </Heading>
          <Styled.List>
            {requirements.map((requirement) => (
              <Text
                as="li"
                dangerouslySetInnerHTML={{
                  __html: parseMarkdown(requirement),
                }}
                key={requirement}
              />
            ))}
          </Styled.List>
        </Styled.Requirements>
        <Styled.ButtonContainer>
          {disabled && <Button disabled>{cta}</Button>}
          {!disabled && (
            <Link href={url} legacyBehavior passHref>
              <Button as="a" secondary target="_blank">
                {cta}
              </Button>
            </Link>
          )}
        </Styled.ButtonContainer>
      </Styled.Container>
    </Styled.Background>
  );
};

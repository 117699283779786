import styled, { css } from 'styled-components';

import * as Types from './Block.types';

export const Block = styled.section<Omit<Types.BlockProps, 'children'>>(
  ({ column }) => css`
    background-color: transparent;
    width: ${column ? '100%' : '100vw'};

    ${column &&
    css`
      & > * {
        padding: 0;
      }
    `}
  `
);

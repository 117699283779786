import styled, { css } from 'styled-components';
import { Block } from '../block/Block.styled';
import { Container as AlternateBlock } from '../alternate-block/AlternateBlock.styled';
import { Background as ContactBlock } from '../contact-block/ContactBlock.styled';

export const Blocks = styled.main(
  ({ theme }) => css`
    display: grid;
    gap: 64px;

    ${Block}:has(${ContactBlock}) + ${Block}:has(${AlternateBlock}) {
      margin-top: -64px;

      @media (min-width: ${theme.breakpoints.medium}) {
        margin-top: -128px;
      }
    }

    > * {
      &:last-child {
        margin-bottom: 64px;

        @media (min-width: ${theme.breakpoints.medium}) {
          margin-bottom: 128px;
        }
      }
    }

    @media (min-width: ${theme.breakpoints.medium}) {
      gap: 128px;
    }
  `
);

import styled, { css } from 'styled-components';

import { Container as ContainerBase } from '@humanoids/ui';

export const Container = styled(ContainerBase)(
  ({ theme }) => css`
    display: grid;
    gap: var(--spacing);
    grid-auto-rows: min-content;

    @media (min-width: ${theme.breakpoints.medium}) {
      grid-auto-flow: column;
    }
  `
);

import styled, { css } from 'styled-components';

import { Container as ContainerBase, theme } from '@humanoids/ui';

interface StyledContainerProps {
  fullWidth: boolean;
}

export const Container = styled(ContainerBase)<StyledContainerProps>(
  ({ fullWidth, theme }) => css`
    height: fit-content;
    margin-left: calc(var(--spacing) * -1);
    margin-right: calc(var(--spacing) * -1);

    @media (min-width: ${theme.breakpoints.medium}) {
      margin: 0 auto;
    }

    ${fullWidth &&
    css`
      display: grid;
      grid-auto-flow: column;
      height: calc(95vw / 16 * 9);
      margin: 0;
      max-width: initial;
      overflow-x: scroll;
      padding: 0;
      scroll-snap-type: x mandatory;

      @media (min-width: ${theme.breakpoints.medium}) {
        height: 320px;
      }

      ${Picture} {
        width: 95vw;

        img {
          object-fit: cover;
        }

        @media (min-width: ${theme.breakpoints.medium}) {
          height: auto;
          width: inherit;
        }
      }
    `}
  `
);

export const imageMediaCondition = `
  (max-width: ${theme.breakpoints.medium}) 100vw,
  1104px
`;

export const imageMediaConditionMultiple = `
  (max-width: ${theme.breakpoints.medium}) 95vw,
  33vw
`;

interface StyledPictureProps {
  $height: number;
  originalAspectRatio: boolean;
  $width: number;
}

export const Picture = styled.div<StyledPictureProps>(
  ({ $height, originalAspectRatio, theme, $width }) => css`
    height: ${`calc(
      ${originalAspectRatio ? '100vw' : '95vw'} /
        ${originalAspectRatio ? $width : 16} *
        ${originalAspectRatio ? $height : 9}
    )`};
    position: relative;
    scroll-snap-align: start;
    width: 100vw;

    img {
      object-fit: ${originalAspectRatio ? 'contain' : 'cover'};
    }

    @media (min-width: ${theme.breakpoints.medium}) {
      max-height: 600px;
      width: inherit;
    }
  `
);

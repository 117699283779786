import { gql } from 'graphql-request';

import { Container, Text, parseMarkdown } from '@humanoids/ui';

import { ListBlockType } from '@/services';

import * as Styled from './ListBlock.styled';

export const listBlockFragment = gql`
  fragment listBlock on ListBlock {
    __typename
    _key
    items
    listStyleType
  }
`;

export const ListBlock = ({ items, listStyleType }: ListBlockType) => (
  <Container>
    <Styled.List listStyleType={listStyleType}>
      {items.map((item) => (
        <Text
          as="li"
          dangerouslySetInnerHTML={{
            __html: parseMarkdown(item),
          }}
          key={item}
          large
        />
      ))}
    </Styled.List>
  </Container>
);

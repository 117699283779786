import Image from 'next/image';
import { imageUrlBuilder } from '../../services/sanity-url-builder';

import { TeamBlockType } from '../../services/graphql';
import * as Styled from './TeamBlock.styled';

export const TeamBlock = ({ compact, teamMembers }: TeamBlockType) => {
  const teamMemberCards = teamMembers.map(
    ({ _id, avatar, name, position }) => ({
      _key: _id,
      image: avatar,
      title: name,
      text: position,
    })
  );

  return (
    <>
      {compact && (
        <Styled.Container>
          {teamMembers.map(({ _id, avatar, name }) => (
            <Styled.TeamMember key={_id}>
              <Styled.Photo>
                <Image
                  alt={name}
                  blurDataURL={avatar.asset.metadata.lqip}
                  fill
                  placeholder="blur"
                  quality={85}
                  sizes={Styled.avatarMediaCondition}
                  src={imageUrlBuilder.image(avatar).url()}
                  title={name}
                />
              </Styled.Photo>
            </Styled.TeamMember>
          ))}
        </Styled.Container>
      )}
      {!compact && <Styled.CardsBlock cards={teamMemberCards} size="small" />}
    </>
  );
};

import styled, { css } from 'styled-components';

import { Block } from '../alternate-block/AlternateBlock.styled';
import { Container as ContainerBase } from '@humanoids/ui';

interface ContainerProps {
  standalone?: boolean;
}

export const Container = styled(ContainerBase)<ContainerProps>(
  ({ standalone, theme }) => css`
    margin-bottom: ${standalone ? '0' : '-32px'};

    ${Block}:has(&) :is(h1, h2, h3, h4, h5) {
      color: ${theme.textColor.contrast};
    }

    @media (min-width: ${theme.breakpoints.medium}) {
      margin-bottom: ${standalone ? '0' : '-64px'};
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      margin-top: -95px;
      padding-top: 95px;
    }
  `
);

import styled, { css } from 'styled-components';

import { Container as ContainerBase, theme } from '@humanoids/ui';
import { CardsBlock as CardsBlockBase } from '../cards-block';

export const Container = styled(ContainerBase)(
  ({ theme }) => css`
    display: flex;
    gap: 16px;
    overflow-x: scroll;
    overscroll-behavior: contain;
    -webkit-overflow-scrolling: touch;

    @media (min-width: ${theme.breakpoints.medium}) {
      display: grid;
      gap: 24px;
      grid-template-columns: repeat(auto-fill, 82px);
      justify-content: space-between;
      overflow-x: auto;
    }
  `
);

export const TeamMember = styled.div(
  ({ theme }) => css`
    --width: calc(25vw - 8px);

    height: calc(var(--width) / 3 * 4);
    width: var(--width);

    @media (min-width: ${theme.breakpoints.medium}) {
      height: 109px;
      width: 82px;
    }
  `
);

export const avatarMediaCondition = `
  (max-width: ${theme.breakpoints.medium}) 25vw,
  82px
`;

export const Photo = styled.div`
  height: inherit;
  position: relative;
  width: inherit;

  img {
    object-fit: cover;
  }
`;

export const CardsBlock = styled(CardsBlockBase)`
  gap: 8px;

  h2 {
    margin-top: 16px;
  }
`;

import styled, { css } from 'styled-components';
import Link from 'next/link';
import { Container as ContainerBase, theme } from '@humanoids/ui';

export const Header = styled.header`
  // TODO: Move to theme variable
  background-color: hsl(60, 10%, 90%);
`;

export const HeaderContainer = styled(ContainerBase)(
  ({ theme }) => css`
    display: grid;
    padding: 0;

    @media (min-width: ${theme.breakpoints.medium}) {
      grid-template-columns: 640px 1fr;
      height: 508px;
      max-width: 1560px;
      padding: 0;
    }
  `
);

export const Wrapper = styled.div(
  ({ theme }) => css`
    display: grid;
    gap: 16px;
    grid-auto-rows: min-content;
    grid-row-start: 2;
    padding: var(--spacing);

    @media (min-width: ${theme.breakpoints.medium}) {
      grid-row-start: initial;
    }
  `
);

export const Date = styled.time`
  font-size: 16px;
`;

export const Image = styled.div(
  ({ theme }) => css`
    grid-row-start: 1;
    height: 265px;
    position: relative;
    width: auto;

    @media (min-width: ${theme.breakpoints.medium}) {
      grid-row-start: initial;
      height: 100%;
    }

    img {
      object-fit: cover;
    }
  `
);

export const imageMediaCondition = `
  (max-width: ${theme.breakpoints.medium}) 100vw,
  60vw
`;

export const Container = styled(ContainerBase)(
  ({ theme }) => css`
    padding: 0 var(--spacing);

    @media (min-width: ${theme.breakpoints.medium}) {
      display: grid;
      gap: 96px;
      grid-template-columns: 1fr 360px;
    }
  `
);

export const Aside = styled.aside`
  align-self: start;
  display: grid;
  gap: 24px;
  padding: var(--spacing) 0;
  position: sticky;
  top: 95px;

  @media (min-width: ${theme.breakpoints.medium}) {
    gap: 32px;
  }
`;

export const Tags = styled.div`
  display: flex;
  gap: 16px;
`;

export const ArticleLink = styled(Link)(
  ({ theme }) => css`
    color: ${theme.textColor.primary};
    display: grid;
    gap: 8px;
    text-decoration: none;

    &:hover h5 {
      text-decoration: underline;
    }
  `
);

// TODO: Change H4 font-size on mobile to 18px and use Heading component
export const ArticleTitle = styled.h5`
  font-size: 18px;
  font-weight: normal;
  line-height: 28px;
`;

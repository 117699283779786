import { Text, parseMarkdown } from '@humanoids/ui';

import * as Styled from './TextBlock.styled';
import { TextBlockType } from '../../services/graphql';

export const TextBlock = ({
  align,
  size,
  standalone,
  text,
  ...props
}: TextBlockType) => (
  <Styled.Container align={align} standalone={standalone}>
    <Text
      align={align}
      dangerouslySetInnerHTML={{
        __html: parseMarkdown(text),
      }}
      small={size === 'small'}
      regular={size === 'regular'}
      large={size === 'large'}
      extraLarge={size === 'extraLarge'}
      extraExtraLarge={size === 'extraExtraLarge'}
      {...props}
    />
  </Styled.Container>
);

import Image from 'next/image';
import { Button, Heading, Text, parseMarkdown } from '@humanoids/ui';

import { useGeneratePath } from '@/hooks/use-generate-path';
import { CardsBlockType } from '@/services';

import * as Styled from './CardsBlock.styled';

const Card = ({
  cta,
  image,
  text,
  title,
  page,
  position,
  size,
  url,
  ...props
}) => (
  <Styled.Card position={position} size={size} {...props}>
    {image && (
      <Styled.Image>
        <Image
          alt={image.alt}
          blurDataURL={image.asset.metadata.lqip}
          fill
          placeholder="blur"
          quality={85}
          sizes={Styled.imageMediaCondition}
          src={image.asset.url}
        />
      </Styled.Image>
    )}
    {(size === 'micro' || size === 'small') && (
      <Heading as="h2" quinary>
        {title}
      </Heading>
    )}
    {(size === 'medium' || size === 'large') && (
      <Heading as="h2" tertiary>
        {title}
      </Heading>
    )}
    {size === 'small' && <Text>{text}</Text>}
    {(size === 'medium' || size === 'large') && (
      <Text
        large
        dangerouslySetInnerHTML={{
          __html: parseMarkdown(text),
        }}
      />
    )}
    {(page || url) && (
      <Button aria-label={size !== 'micro' ? cta : title} tabIndex={-1}>
        {size !== 'micro' && cta}
      </Button>
    )}
  </Styled.Card>
);

export const CardsBlock = ({ cards, size, ...props }: CardsBlockType) => {
  const generatePath = useGeneratePath();

  return (
    <Styled.Container size={size}>
      {cards.map(({ _key, cta, image, text, title, page, position, url }) =>
        page || url ? (
          <Styled.Link
            href={page ? generatePath(page) : url}
            key={_key}
            target={url ? (url.startsWith('/') ? '_self' : '_blank') : '_self'}
          >
            <Card
              {...{ cta, image, text, title, page, position, size, url }}
              {...props}
            />
          </Styled.Link>
        ) : (
          <Card
            key={_key}
            {...{ cta, image, text, title, page, position, size, url }}
            {...props}
          />
        )
      )}

      {size === 'medium' && cards.length % 2 === 1 && (
        <Styled.FireworksDoodleImage>
          <Image
            alt="fireworks doodle"
            fill
            src="/images/fireworks-doodle.svg"
          />
        </Styled.FireworksDoodleImage>
      )}
    </Styled.Container>
  );
};

import { ArticlesLatestBlock } from '../articles-latest-block';
import { Block } from '../block';
import { CardsBlock } from '../cards-block';
import { CaseSelectionBlock } from '../case-selection-block';
import { HeadingBlock } from '../heading-block';
import { ImagesBlock } from '../images-block';
import { TeamBlock } from '../team-block';
import { TextBlock } from '../text-block';
import { ColumnsBlockType } from '@/services';
import * as Styled from './ColumnsBlock.styled';

export const ColumnsBlock = ({ columns }: ColumnsBlockType) => (
  <Styled.Container>
    {columns?.map((block) => (
      <Block column key={block._key}>
        {block.__typename === 'ArticlesLatestBlock' && (
          <ArticlesLatestBlock {...block} />
        )}
        {block.__typename === 'CardsBlock' && <CardsBlock {...block} />}
        {block.__typename === 'CaseSelectionBlock' && (
          <CaseSelectionBlock {...block} />
        )}
        {block.__typename === 'HeadingBlock' && <HeadingBlock {...block} />}
        {block.__typename === 'ImagesBlock' && <ImagesBlock {...block} />}
        {block.__typename === 'TeamBlock' && <TeamBlock {...block} />}
        {block.__typename === 'TextBlock' && <TextBlock {...block} />}
      </Block>
    ))}
  </Styled.Container>
);

import React, { useContext } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/compat/router';

import { PageType } from '@/services';
import { StoreContext } from '../store-provider';
import { useGeneratePath } from '@/hooks/use-generate-path';

interface SeoProps {
  page: PageType;
}

export const Seo = ({ page }: SeoProps) => {
  const router = useRouter();
  const [{ translationMetadata }] = useContext(StoreContext);

  const languages = router.locales;
  const defaultLanguage = router.defaultLocale;

  const generatePath = useGeneratePath();

  const findTranslationMetadata = (id, language) => {
    const result = translationMetadata
      .find(
        ({ translations }) =>
          translations?.some((translation) => translation.value?._id === id)
      )
      .translations.find(({ value }) => value?.i18n_lang === language)?.value;
    return result;
  };

  const generateAlternate = (page, language) => {
    const alternatePage = findTranslationMetadata(page._id, language);

    const alternatePageParent = findTranslationMetadata(
      alternatePage.parentPage?._id,
      language
    );

    return generatePath({
      ...alternatePage,
      ...(alternatePageParent
        ? {
            parentPage: {
              i18n_lang: alternatePageParent.i18n_lang,
              slug: alternatePageParent.slug,
            },
          }
        : {}),
    });
  };

  const alternates = languages.map((language) => ({
    language,
    url: generateAlternate(page, language),
  }));

  return (
    <Head>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
      <title>{`${page.title} 🤖 Humanoids`}</title>
      {(process.env.NEXT_PUBLIC_HOSTNAME.startsWith('https://tst') ||
        process.env.NEXT_PUBLIC_HOSTNAME.startsWith('https://acc')) && (
        <meta name="robots" content="noindex" />
      )}
      <meta name="title" content={page.title} />
      <meta name="description" content={page?.description} />
      <meta
        name="url"
        content={`${process.env.NEXT_PUBLIC_HOSTNAME}${generatePath(page)}`}
      />
      {alternates.map((alternate) => (
        <link
          key={alternate.url}
          rel="alternate"
          hrefLang={alternate.language}
          href={`${process.env.NEXT_PUBLIC_HOSTNAME}${
            alternate.language === defaultLanguage ? `/` : `/en/`
          }`}
        />
      ))}
      {page.private && <meta name="robots" content="noindex,nofollow" />}
    </Head>
  );
};

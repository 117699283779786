import { Heading } from '@humanoids/ui';

import { HeadingBlockType } from '@/services';
import * as Styled from './HeadingBlock.styled';

export const HeadingBlock = ({
  id,
  level = 'h1',
  standalone,
  title,
  ...props
}: HeadingBlockType) => (
  <Styled.Container standalone={standalone}>
    <Heading
      id={id?.current ? id.current : null}
      quaternary={level === 'h4'}
      primary={level === 'h1'}
      secondary={level === 'h2'}
      tertiary={level === 'h3'}
      {...props}
    >
      {title}
    </Heading>
  </Styled.Container>
);

import styled, { css } from 'styled-components';
import LinkBase from 'next/link';

import {
  Card as CardBase,
  Container as ContainerBase,
  theme,
} from '@humanoids/ui';

export const Container = styled(ContainerBase)(
  ({ theme }) => css`
    display: grid;
    gap: var(--spacing);
    grid-template-columns: 1fr;

    width: 100%;

    @media (min-width: ${theme.breakpoints.medium}) {
      grid-template-columns: 1fr 1fr;
    }
  `
);

export const imageMediaCondition = `
  (max-width: ${theme.breakpoints.medium}) 100vw,
  718px
`;

export const Image = styled.div`
  height: 280px;
  position: relative;
  width: auto;

  img {
    object-fit: cover;
  }
`;

export const Overlay = styled.div`
  background: linear-gradient(
    133.96deg,
    rgba(0, 0, 0, 0.54) 0%,
    rgba(0, 0, 0, 0.54) 24.75%,
    rgba(0, 0, 0, 0) 100%
  );
  height: 280px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const Link = styled(LinkBase)(
  ({ theme }) => css`
    color: ${theme.textColor.primary};
    text-decoration: none;
    position: relative;

    &:first-child&:nth-last-child(3) {
      @media (min-width: ${theme.breakpoints.medium}) {
        display: grid;
        grid-template-columns: 65% 1fr;
        grid-column-end: span 2;
        grid-column-start: 1;
        height: 480px;

        p {
          -webkit-line-clamp: 7;
        }

        ${Card} {
          height: 480px;
        }

        ${Image} {
          height: inherit;
          width: auto;
        }

        ${Overlay} {
          height: 480px;
        }
      }
    }
  `
);

export const Card = styled(CardBase)`
  @media (min-width: ${theme.breakpoints.medium}) {
    grid-auto-rows: min-content 1fr;

    h3 {
      white-space: nowrap;
    }
  }
`;

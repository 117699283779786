import styled, { css } from 'styled-components';

import {
  Card as CardBase,
  Container as ContainerBase,
  theme,
} from '@humanoids/ui';
import LinkBase from 'next/link';

interface StyledCardsProps {
  position: string;
  size: string;
}

export const Container = styled(ContainerBase)<{ size: string }>(
  ({ size, theme }) => css`
    display: grid;
    gap: var(--spacing);
    grid-template-columns: 1fr;

    ${size === 'micro' &&
    css`
      gap: calc(var(--spacing) * 0.5);

      ${Card} {
        align-items: center;
        cursor: pointer;
        grid-auto-flow: column;
        grid-template-columns: 1fr min-content;
        grid-template-rows: none;
        padding: calc(var(--spacing) / 4);

        h2 {
          margin-left: 8px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        button {
          justify-self: end;
          margin-right: 4px;
          padding: 10px;

          @media (min-width: ${theme.breakpoints.medium}) {
            padding: 12px;
          }
        }
      }
    `}

    @media (min-width: ${theme.breakpoints.medium}) {
      grid-template-columns: repeat(${size === 'medium' ? 2 : 3}, 1fr);

      ${size === 'large' &&
      css`
        grid-template-columns: 1fr;
      `}
    }
  `
);

export const imageMediaCondition = `
  (max-width: ${theme.breakpoints.medium}) 100vw,
  33vw
`;

export const Image = styled.div`
  position: relative;
  width: auto;

  img {
    object-fit: cover;
  }
`;

export const Link = styled(LinkBase)`
  color: ${theme.textColor.primary};
  text-decoration: none;
`;

export const Card = styled(CardBase)<StyledCardsProps>(
  ({ position, size }) => css`
    grid-template-rows: auto 1fr;
    height: 100%;

    p {
      -webkit-line-clamp: unset;
    }

    &:has(${Image}) {
      grid-template-rows: ${size !== 'large' && '200px auto 1fr'};
    }

    @media (min-width: ${theme.breakpoints.medium}) {
      ${size === 'large' &&
      css`
        grid-template-rows: auto 1fr;
        grid-template-columns: repeat(3, 1fr);
      `}
    }

    ${Image} {
      margin: ${size === 'small' ? '-24px' : 'calc(var(--spacing) * -1)'};
      margin-bottom: 0;

      //TODO: Remove when Firefox :has() selector is implemented
      height: 224px;

      @media (min-width: ${theme.breakpoints.medium}) {
        margin-bottom: ${size !== 'large' && '0'};

        ${size === 'large' &&
        css`
          grid-column: ${position === 'left' ? 1 : 2} / span
            ${position === 'left' ? 2 : 3};
          grid-row: 1 / span 3;
          height: 400px;
          margin: calc(var(--spacing) * -1)
            ${position === 'left' ? '0' : 'calc(var(--spacing) * -1)'}
            calc(var(--spacing) * -1)
            ${position === 'left' ? 'calc(var(--spacing) * -1)' : '0'};
        `}
      }
    }
  `
);

export const FireworksDoodleImage = styled.div(
  ({ theme }) => css`
    display: none;

    @media (min-width: ${theme.breakpoints.medium}) {
      align-self: center;
      display: block;
      fill: ${theme.textColor.primary};
      height: 71px;
      justify-self: center;
      position: relative;
      width: 78px;
    }
  `
);

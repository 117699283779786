import styled, { css } from 'styled-components';

import {
  Button as ButtonBase,
  Container as ContainerBase,
  Heading as HeadingBase,
  Text as TextBase,
  theme,
} from '@humanoids/ui';

import { Block } from '../block/Block.styled';

export const Background = styled.div(
  ({ theme }) => css`
    background-color: ${theme.backgroundColor.quaternary};

    @media (min-width: ${theme.breakpoints.medium}) {
      padding: 64px 0;
    }

    ${Block}:has(&) {
      &:last-child {
        margin-bottom: 0px;
      }
    }
  `
);

export const Heading = styled(HeadingBase)`
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 var(--spacing);
  width: 100%;
`;

export const Container = styled(ContainerBase)(
  ({ theme }) => css`
    display: grid;
    gap: 64px;
    grid-template-rows: auto 1fr;
    padding: 32px 24px;
    position: relative;

    @media (min-width: ${theme.breakpoints.medium}) {
      gap: 64px;
      grid-template-columns: auto 1fr 1fr;
      grid-template-rows: none;
      padding: 42px var(--spacing) 0;
    }
  `
);

export const StripesDoodleImage = styled.div(
  ({ theme }) => css`
    height: 55px;
    left: 34px;
    position: absolute;
    top: 10px;
    width: 55px;

    @media (min-width: ${theme.breakpoints.medium}) {
      height: 70px;
      left: -16px;
      top: 4px;
      width: 70px;
    }
  `
);

export const FireworksDoodleImage = styled.div(
  ({ theme }) => css`
    display: none;

    @media (min-width: ${theme.breakpoints.medium}) {
      display: block;
      height: 71px;
      position: absolute;
      right: 40px;
      width: 78px;
      top: -10px;
    }
  `
);

export const imageMediaCondition = `
    (max-width: ${theme.breakpoints.medium}) 240px,
  332px
`;

export const Avatar = styled.div(
  ({ theme }) => css`
    display: block;
    height: 240px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    width: 240px;

    @media (min-width: ${theme.breakpoints.medium}) {
      height: 332px;
      margin: 0;
      width: 332px;
    }

    img {
      border-radius: 30px;
    }
  `
);

export const TextContainer = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-template-rows: min-content min-content auto;
    gap: 32px;

    @media (min-width: ${theme.breakpoints.medium}) {
      gap: 24px;
    }
  `
);

export const ContactDetails = styled.div`
  display: grid;
  gap: 12px;
`;

export const Text = styled(TextBase)`
  display: grid;
  font-weight: 700;

  a {
    color: ${theme.brandColors.secondary.dark};
    font-weight: 400;
  }
`;

export const StartInquiry = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: -32px;

  @media (min-width: ${theme.breakpoints.medium}) {
    margin-top: 0;
  }
`;

export const Button = styled(ButtonBase)`
  justify-content: center;
  width: inherit;
`;

import styled, { css } from 'styled-components';

import {
  Container as ContainerBase,
  Text as TextBase,
  theme,
} from '@humanoids/ui';

export const Container = styled(ContainerBase)(
  ({ theme }) => css`
    display: grid;
    gap: 24px var(--spacing);

    @media (min-width: ${theme.breakpoints.medium}) {
      align-items: center;
      gap: 48px;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto 1fr;

      ${Image} {
        @media (min-width: ${theme.breakpoints.medium}) {
        }
      }
    }
  `
);

export const Text = styled(TextBase)(
  ({ theme }) => css`
    @media (min-width: ${theme.breakpoints.medium}) {
      align-self: start;
    }
  `
);

export const Image = styled.div<{ position: string }>(
  ({ position, theme }) => css`
    height: calc(100vw / 16 * 9);
    margin-left: calc(var(--spacing) * -1);
    margin-right: calc(var(--spacing) * -1);
    position: relative;

    @media (min-width: ${theme.breakpoints.medium}) {
      align-self: center;
      grid-column: ${position === 'left' ? 1 : 2} /
        ${position === 'left' ? 2 : 3};
      grid-row: 1 / span 2;
      height: calc(50% * 16 / 10);
      margin: 0;
    }

    img {
      object-fit: cover;
    }
  `
);

export const imageMediaCondition = `
  (max-width: ${theme.breakpoints.medium}) 100vw,
  60vw
`;

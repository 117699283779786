import { AccordionBlock } from '../accordion-block';
import { AlternateBlock } from '../alternate-block';
import { ArticleBlock } from '../article-block';
import { ArticlesLatestBlock } from '../articles-latest-block';
import { Block } from '../block';
import { CardsBlock } from '../cards-block';
import { CaseBlock } from '../case-block';
import { CaseSelectionBlock } from '../case-selection-block';
import { ColumnsBlock } from '../columns-block';
import { ContactBlock } from '../contact-block';
import { HeadingBlock } from '../heading-block';
import { HeroBlock } from '../hero-block';
import { ImagesBlock } from '../images-block';
import { LineBlock } from '../line-block';
import { ListBlock } from '../list-block';
import { OverviewBlock } from '../overview-block';
import { PicturesBlock } from '../pictures-block';
import { QuoteBlock } from '../quote-block';
import { TeamBlock } from '../team-block';
import { TextBlock } from '../text-block';
import { TextImageBlock } from '../text-image-block';
import { TraineeshipApplicationBlock } from '../traineeship-application-block';

import { PageType } from '@/services';

import * as Styled from './Blocks.styled';

interface BlocksProps {
  page: PageType;
}

// TODO: Strip page object to metadata
// TODO: Consider to render this automatically
export const Blocks = ({ page }: BlocksProps) => (
  <Styled.Blocks>
    {page.blocks.map((block) => (
      <Block key={block._key}>
        {block.__typename === 'AccordionBlock' && <AccordionBlock {...block} />}
        {block.__typename === 'AlternateBlock' && <AlternateBlock {...block} />}

        {block.__typename === 'ArticleBlock' && (
          <ArticleBlock {...block} page={page} />
        )}
        {block.__typename === 'ArticlesLatestBlock' && (
          <ArticlesLatestBlock {...block} />
        )}
        {block.__typename === 'CardsBlock' && <CardsBlock {...block} />}
        {block.__typename === 'CaseBlock' && (
          <CaseBlock {...block} page={page} />
        )}
        {block.__typename === 'CaseSelectionBlock' && (
          <CaseSelectionBlock {...block} />
        )}
        {block.__typename === 'ColumnsBlock' && <ColumnsBlock {...block} />}
        {block.__typename === 'ContactBlock' && <ContactBlock {...block} />}
        {block.__typename === 'HeadingBlock' && <HeadingBlock {...block} />}
        {block.__typename === 'ImagesBlock' && <ImagesBlock {...block} />}
        {block.__typename === 'HeroBlock' && <HeroBlock {...block} />}
        {block.__typename === 'LineBlock' && <LineBlock />}
        {block.__typename === 'ListBlock' && <ListBlock {...block} />}
        {block.__typename === 'OverviewBlock' && <OverviewBlock {...block} />}
        {block.__typename === 'PicturesBlock' && <PicturesBlock {...block} />}
        {block.__typename === 'QuoteBlock' && <QuoteBlock {...block} />}
        {block.__typename === 'TeamBlock' && <TeamBlock {...block} />}
        {block.__typename === 'TextBlock' && <TextBlock {...block} />}
        {block.__typename === 'TextImageBlock' && <TextImageBlock {...block} />}
        {block.__typename === 'TraineeshipApplicationBlock' && (
          <TraineeshipApplicationBlock {...block} />
        )}
      </Block>
    ))}
  </Styled.Blocks>
);

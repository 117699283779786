import styled, { css } from 'styled-components';

import { theme } from '@humanoids/ui';

export const RichText = styled.article(
  ({ theme }) => css`
    display: grid;
    gap: 24px;
    column-gap: 64px;
    grid-auto-flow: row dense;
    grid-template-columns: 1fr 1fr;
    line-height: 36px;
    padding: var(--spacing) 0 0;

    *:not(pre > *) {
      grid-column: 1 / span 2;
      word-break: keep-all;
      word-wrap: break-word;
    }

    p {
      &:first-child {
        font-size: calc(1.2 * var(--fontSize));
        line-height: calc(1.2 * var(--lineHeight));

        @media (min-width: ${theme.breakpoints.medium}) {
          font-size: calc(1.3 * var(--fontSize));
          line-height: calc(1.3 * var(--lineHeight));
        }
      }
    }

    ol,
    ul {
      font-size: 20px;
      line-height: 32px;
      padding-left: 32px;

      @media (min-width: ${theme.breakpoints.medium}) {
        font-size: 24px;
        line-height: 38px;
        padding-left: 64px;
      }
    }

    a:hover {
      text-decoration: none;
    }

    @media (min-width: ${theme.breakpoints.medium}) {
      h2:has(+ p + figure.left),
      h3:has(+ p + figure.left),
      h4:has(+ p + figure.left) {
        grid-column: 2;
        grid-row: span 1;
      }

      h2:not(:first-child),
      h3:not(:first-child),
      h4:not(:first-child),
      figure:not(:first-of-type),
      figure.fullWidth {
        padding-top: 40px;
      }

      p:has(+ figure.left) {
        grid-column: 2;
        grid-row: span 3;
        width: 100%;
      }

      h2:has(+ p + figure.right),
      h3:has(+ p + figure.right),
      h4:has(+ p + figure.right) {
        grid-column: 1;
        grid-row: span 1;
      }

      p:has(+ figure.right) {
        grid-column: 1;
        grid-row: span 3;
        width: 100%;
      }
    }
  `
);

export const Figure = styled.figure(
  ({ theme }) => css`
    margin: 0 calc(0px - var(--spacing));
    align-self: center;

    @media (min-width: ${theme.breakpoints.medium}) {
      margin: initial;

      &.fullWidth {
        grid-column: 1 / span 2;
      }

      /**
       * Having a span of 4 is a workaround to accomplish
       * aligning the accompanying content at the top of
       * the image instead of starting at the middle
       */
      &.left,
      &.right {
        grid-row: span 4;
      }

      &.left {
        grid-column: 1;
      }

      &.right {
        grid-column: 2;
      }
    }
  `
);

export const Image = styled.div(
  ({ theme }) => css`
    position: relative;
    width: 100%;

    img {
      object-fit: cover;
      position: static !important;
    }

    @media (min-width: ${theme.breakpoints.medium}) {
      img {
        object-fit: contain;
      }
    }
  `
);

export const imageMediaCondition = `
  (max-width: ${theme.breakpoints.medium}) 100vw,
  1136px
`;

const captionCss = css`
  font-size: 16px;
  line-height: 36px;
  text-align: center;
`;

export const Caption = styled.figcaption`
  ${captionCss}
`;

export const Video = styled.video(
  ({ theme }) => css`
    height: calc(100vw / 16 * 9);
    margin: 0 calc(0px - var(--spacing));
    width: 100vw;

    @media (min-width: ${theme.breakpoints.medium}) {
      height: initial;
      margin: initial;
      width: 100%;
    }
  `
);

export const VideoCaption = styled.div`
  ${captionCss}

  margin-top: -24px;
`;

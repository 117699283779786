import styled, { css } from 'styled-components';

import { Block } from '../alternate-block/AlternateBlock.styled';
import { Container as ContainerBase } from '@humanoids/ui';

interface ContainerProps {
  align: string;
  standalone: boolean;
}

export const Container = styled(ContainerBase)<ContainerProps>(
  ({ align, standalone, theme }) => css`
    margin-top: ${standalone ? '0' : '-34px'};

    ${Block}:has(&) {
      color: ${theme.textColor.contrast};
    }

    @media (min-width: ${theme.breakpoints.medium}) {
      margin-top: ${standalone ? '0' : '-64px'};

      ${(align === 'left' || align === 'right') &&
      css`
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        p {
          grid-column: ${align === 'left' ? '1 / span 2' : '2 / span 3'};

          ${standalone &&
          css`
            grid-column: 1 / span 3;
            padding: 0 calc(var(--spacing) * 4);
          `}
        }
      `}

      ${align === 'center' &&
      css`
        padding-left: calc(var(--spacing) * 2);
        padding-right: calc(var(--spacing) * 2);
      `}
    }

    @media (min-width: ${theme.breakpoints.large}) {
      ${align === 'center' &&
      css`
        padding-left: calc(var(--spacing) * 4);
        padding-right: calc(var(--spacing) * 4);
      `}
    }
  `
);

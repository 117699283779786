import { useContext } from 'react';
import Image from 'next/image';
import { useIntl } from 'react-intl';

import { Button, Heading, Text } from '@humanoids/ui';

import { StoreContext } from '../store-provider';
import { useGeneratePath } from '@/hooks/use-generate-path';
import { imageUrlBuilder, OverviewBlockType, PageType } from '@/services';

import * as Styled from './OverviewBlock.styled';

export const OverviewBlock = ({ type }: OverviewBlockType) => {
  const [{ articles, cases }] = useContext(StoreContext);
  const generatePath = useGeneratePath();
  const intl = useIntl();

  return (
    <Styled.Container>
      {((type === 'article' ? articles : cases) as PageType[]).map(
        ({ _id, author, client, description, image, title, ...page }) => (
          <Styled.Link href={generatePath(page)} key={_id}>
            <Styled.Image>
              <Image
                alt={image.alt}
                blurDataURL={image.asset.metadata.lqip}
                fill
                placeholder="blur"
                quality={85}
                sizes={Styled.imageMediaCondition}
                src={image.asset.url}
              />
              {type === 'case' && <Styled.Overlay />}
            </Styled.Image>
            {type === 'case' && (
              <Styled.Heading forwardedAs="h2" quaternary>
                {client}
              </Styled.Heading>
            )}
            <Styled.Card size="small">
              {type === 'case' && (
                <>
                  <Heading as="h3" quaternary>
                    {title}
                  </Heading>
                  <Text small>{description}</Text>
                  <Button>
                    {intl.formatMessage({
                      id: 'viewThisCase',
                    })}
                  </Button>
                </>
              )}
              {type === 'article' && (
                <>
                  <Styled.Text large>{title}</Styled.Text>
                  <Styled.Author
                    avatar={imageUrlBuilder
                      .image(author.avatar)
                      .size(64, 64)
                      .url()}
                    name={author.name}
                  />
                </>
              )}
            </Styled.Card>
          </Styled.Link>
        )
      )}
    </Styled.Container>
  );
};

import Image from 'next/image';
import { useIntl } from 'react-intl';

import { Button, Heading, Text } from '@humanoids/ui';
import { Heading as StyledHeading } from '../overview-block/OverviewBlock.styled';

import { useGeneratePath } from '@/hooks/use-generate-path';
import { CaseSelectionBlockType } from '@/services';

import * as Styled from './CaseSelectionBlock.styled';

export const CaseSelectionBlock = ({ cases }: CaseSelectionBlockType) => {
  const generatePath = useGeneratePath();
  const intl = useIntl();

  return (
    <Styled.Container>
      {cases.map(({ _id, client, description, image, title, ...page }) => (
        <Styled.Link href={generatePath(page)} key={_id}>
          <Styled.Image>
            <Image
              alt={image.alt}
              blurDataURL={image.asset.metadata.lqip}
              fill
              placeholder="blur"
              quality={90}
              sizes={Styled.imageMediaCondition}
              src={image.asset.url}
            />
            <Styled.Overlay />
          </Styled.Image>
          <StyledHeading forwardedAs="h3" quaternary>
            {client}
          </StyledHeading>
          <Styled.Card>
            <Heading as="h4" tertiary>
              {title}
            </Heading>
            <Text large>{description}</Text>
            <Button tabIndex={-1}>
              {intl.formatMessage({
                id: 'viewThisCase',
              })}
            </Button>
          </Styled.Card>
        </Styled.Link>
      ))}
    </Styled.Container>
  );
};

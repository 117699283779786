import styled, { css } from 'styled-components';

import { Container as ContainerBase, theme } from '@humanoids/ui';

export const Container = styled(ContainerBase)(
  ({ theme }) => css`
    display: grid;
    justify-content: space-between;
    gap: 16px;
    grid-template-columns: 1fr 1fr;

    &:has(p) {
      grid-template-columns: 1fr;
    }

    @media (min-width: ${theme.breakpoints.medium}) {
      gap: var(--spacing);
      grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));

      &:has(p) {
        gap: calc(var(--spacing) * 0.5);
        grid-template-columns: repeat(auto-fit, minmax(256px, auto));
      }
    }
  `
);

export const ImageWrapper = styled.div(
  ({ theme }) => css`
    background-color: #ffffff;
    height: 86px;

    &:has(p) {
      border: 1px solid ${theme.textColor.secondary};
      display: grid;
      gap: var(--spacing);
      grid-template-columns: minmax(auto, 86px) 1fr;
      height: fit-content;
      margin: 0 auto;
      padding: 16px;
      width: 100%;

      @media (min-width: ${theme.breakpoints.medium}) {
        p {
          text-align: center;
        }
      }
    }

    @media (min-width: ${theme.breakpoints.medium}) {
      min-height: 152px;

      &:has(p) {
        grid-template-columns: none;
        grid-template-rows: minmax(auto, 86px) min-content;
        min-height: 242px;
        width: 256px;
      }
    }
  `
);

export const imageMediaCondition = `
  (max-width: ${theme.breakpoints.medium}) 50vw,
  176px
`;

export const Image = styled.div`
  height: 100%;
  margin: auto;
  position: relative;
  width: 100%;

  img {
    object-fit: contain;
  }
`;

export const TextWrapper = styled.div`
  display: grid;
  gap: 6px;

  @media (min-width: ${theme.breakpoints.medium}) {
    justify-items: center;
  }
`;

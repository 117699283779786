import Image from 'next/image';
import { Container } from '@humanoids/ui';

import * as Styled from './LineBlock.styled';

export const LineBlock = () => (
  <Container>
    <Styled.LineDoodle>
      <Image alt="line doodle" fill src="/images/line-doodle.svg" />
    </Styled.LineDoodle>
  </Container>
);

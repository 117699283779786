import styled, { css } from 'styled-components';
import LinkBase from 'next/link';

import {
  Card as CardBase,
  Container as ContainerBase,
  theme,
} from '@humanoids/ui';

export const Container = styled(ContainerBase)(
  ({ theme }) => css`
    display: grid;
    gap: var(--spacing);
    grid-template-rows: 1fr;
    width: 100%;

    @media (min-width: ${theme.breakpoints.medium}) {
      grid-template-columns: repeat(auto-fit, minmax(184px, 256px));
      grid-template-rows: none;
      justify-content: end;
      padding: var(--spacing) 0;
    }
  `
);

export const imageMediaCondition = `
  (max-width: ${theme.breakpoints.medium}) 100vw,
  256px
`;

export const Image = styled.div`
  height: 106px;
  position: relative;
  width: auto;

  img {
    object-fit: cover;
  }
`;

export const Link = styled(LinkBase)(
  ({ theme }) => css`
    color: ${theme.textColor.primary};
    text-decoration: none;

    &:hover p {
      text-decoration: underline;
    }
  `
);

export const Card = styled(CardBase)(
  ({ theme }) => css`
    align-content: space-between;
    background-color: #ffffff;
    display: grid;
    grid-auto-rows: min-content min-content;
    height: fit-content;

    p {
      -webkit-line-clamp: unset;
    }

    @media (min-width: ${theme.breakpoints.medium}) {
      gap: 0;
      height: 240px;
    }
  `
);

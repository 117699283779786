import styled, { css } from 'styled-components';

import {
  Container as ContainerBase,
  Heading as HeadingBase,
  Text as TextBase,
} from '@humanoids/ui';

import { ReactComponent as LineDoodleSvg } from '@/images/line-doodle.svg';

export const Header = styled.header`
  position: relative;
`;

export const Image = styled.div(
  ({ theme }) => css`
    grid-row-start: 1;
    height: 265px;
    position: relative;
    width: 100vw;

    @media (min-width: ${theme.breakpoints.medium}) {
      grid-row-start: initial;
      height: 480px;
      width: auto;
    }

    img {
      object-fit: cover;
    }
  `
);

export const imageMediaCondition = `
  100vw
`;

export const Overlay = styled.div(
  ({ theme }) => css`
    background: linear-gradient(
      133.96deg,
      rgba(0, 0, 0, 0.54) 0%,
      rgba(0, 0, 0, 0.54) 24.75%,
      rgba(0, 0, 0, 0) 100%
    );
    color: #ffffff;
    display: grid;
    gap: 8px;
    grid-auto-rows: min-content;
    height: 265px;
    left: 0;
    padding: 16px var(--spacing);
    position: absolute;
    top: 0;
    width: 100%;

    @media (min-width: ${theme.breakpoints.medium}) {
      height: 480px;
      padding: 64px;
    }
  `
);

export const LineDoodle = styled(LineDoodleSvg)`
  display: block;
  height: 8px;
  width: 64px;

  path {
    fill: #ffffff;
  }
`;

export const Container = styled(ContainerBase)(
  ({ theme }) => css`
    display: grid;
    gap: 64px;
    grid-auto-rows: min-content;
    padding-top: 64px;

    @media (min-width: ${theme.breakpoints.medium}) {
      padding-top: 128px;
    }
  `
);

export const Heading = styled(HeadingBase)`
  /* TODO: '-webkit-hyphens' is used for support in Safari, remove this when 'hyphens' is also supported by Safari */
  -webkit-hyphens: auto;
  hyphens: auto;
  overflow-wrap: break-word;
  overflow: hidden;
  text-align: center;
`;

export const Text = styled(TextBase)(
  ({ theme }) => css`
    text-align: center;

    @media (min-width: ${theme.breakpoints.medium}) {
      margin: 0 auto;
      max-width: 800px;
    }
  `
);

export const Badges = styled.div(
  ({ theme }) => css`
    align-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    gap: 28px 10px;
    justify-content: center;

    @media (min-width: ${theme.breakpoints.medium}) {
      margin: 0 auto;
      max-width: 600px;
    }
  `
);

export const RichContent = styled(ContainerBase)(
  ({ theme }) => css`
    padding-top: 40px;

    @media (min-width: ${theme.breakpoints.medium}) {
      padding-top: 80px;

      p,
      ol,
      ul {
        width: 80%;
      }
    }

    figure {
      img {
        object-fit: contain;
      }

      @media (min-width: ${theme.breakpoints.medium}) {
        &.fullWidth {
          div {
            min-height: 653px;
          }
        }
      }
    }
  `
);
